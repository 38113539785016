.file-dropzone {
    border: 2px dashed #ccc; /* Dashed border always visible */
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    background: linear-gradient(135deg, #f9f9f9, #ffffff); /* Subtle gradient */
    transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05); /* Lighter shadow */
  }
  
  .file-dropzone:hover {
    background: linear-gradient(135deg, #f0f0f0, #f8f8f8);
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1); /* Slightly more pronounced shadow */
    border-color: #aaa; /* Darker border on hover */
    transform: scale(1.01); /* Slight scaling on hover */
  }
  
  .file-dropzone.dragover {
    background: linear-gradient(135deg, #c4e0e5, #f2fcfe); /* Cool gradient */
    border-color: #00bfff; /* Bright border when dragging over */
    box-shadow: 0px 8px 20px rgba(0, 191, 255, 0.3); /* Enhanced shadow */
    transform: scale(1.02); /* Slightly more scaling */
  }
  
  .file-dropzone p {
    font-size: 18px;
    color: #555;
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    opacity: 0.9;
    transition: color 0.3s ease, opacity 0.3s ease;
  }
  
  .file-dropzone.dragover p {
    color: #00bfff; /* Text matches the border during drag */
    opacity: 1;
  }
  
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  .file-dropzone:active {
    background: linear-gradient(135deg, #e0e0e0, #d4d4d4);
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.2);
    transform: scale(0.98);
  }